<template>
	<BaseEmpty>
		<template>
			<Toast
				:visible="connectionError"
				variant="error"
			/>
			<div class="filter-wrap mb-2">
				<div
					class="filter"
					@click="filterDropDownActive"
					v-bind:class="[showFiltersForm ? 'filter-active' : '']"
				>
					<span class="title">
						Filtros
					</span>
					<FilterIcon/>
				</div>
			</div>

			<transition name="slide-fade">
				<div v-if="showFiltersForm" class="filters-form content-card mb-3">
					<div>
						<b-row class="pt-3 pl-3 pr-3">
							<b-col cols="5">
								<b-form-group label="Injetora *">
									<b-form-select v-model="selectedMachine" :options="maquinasOptions" :state="validateMaquina()"></b-form-select>
									<b-form-invalid-feedback :state="validateMaquina()">Campo obrigatório.</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
							<b-col cols="3">
								<b-form-group label="Intervalo entre os pontos *">
									<b-form-select v-model="intervaloSelected" :options="intervalosOptions" :state="validateIntervaloPontos()"></b-form-select>
									<b-form-invalid-feedback :state="validateIntervaloPontos()">Campo obrigatório.</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
							<b-col cols="4">
								<b-form-group label="Intervalo de visualização em minutos *">
									<b-form-input type="number" v-model="intervaloEmMinutos" @change="filtrosRapidosClear()" :state="validateIntervalo()"></b-form-input>
									<b-form-invalid-feedback :state="validateIntervalo()">Campo obrigatório. Preencha com um valor numérico válido.</b-form-invalid-feedback>
								</b-form-group>
							</b-col>
						</b-row>
						<b-row class="pl-3">
							<b-col>
								<p>Filtros rápidos de visualização</p>
							</b-col>
						</b-row>
						<b-row class="pl-3">
							<b-col>
								<b-button :pressed.sync="estadoFiltrosRapidos.um" class="filter-button rounded-pill" @click="filtroRapidoClicked(1, 'um')">1 minuto</b-button>
								<b-button :pressed.sync="estadoFiltrosRapidos.cinco" class="filter-button rounded-pill" @click="filtroRapidoClicked(5, 'cinco')">5 minutos</b-button>
								<b-button :pressed.sync="estadoFiltrosRapidos.dez" class="filter-button rounded-pill" @click="filtroRapidoClicked(10, 'dez')">10 minutos</b-button>
								<b-button :pressed.sync="estadoFiltrosRapidos.trinta" class="filter-button rounded-pill" @click="filtroRapidoClicked(30, 'trinta')">30 minutos</b-button>
							</b-col>
						</b-row>
						<div class="search-button" @click="search">
							<SearchIcon/>
						</div>
					</div>
				</div>
			</transition>

			<div :class="{ 'content-offset': connectionError, 'content-no-offset': !connectionError }">
				<b-row>
					<b-col cols="12" md="6" xl="3" class="mb-2 p-0 pr-md-2">
						<section class="border-w-10 h-100 d-flex flex-column">
							<div class="content-card">
								<div class="content-card-title content-card-title-color">
									<h4>Ordem de Produção</h4>
									<CalendarIcon/>
								</div>
								<div class="body-cards">
									<div>
										Data e hora de inicialização do processo:
									</div>
									<div>
										{{ machine.eventoProducao.minDataInicial }}
									</div>
								</div>
							</div>
							<div
								class="footer-card ordem-producao d-flex align-items-center justify-content-center"
								v-bind:class="classCardsHeader"
								:title="semOrdemProducao ? '' : machine.ordemProducao.nomeProcesso"
							>
								<span>
									{{ semOrdemProducao ? "" : machine.ordemProducao.nomeProcesso }}
								</span>
							</div>
						</section>
					</b-col>

					<b-col cols="12" md="6" xl="3" class="mb-2 p-0 pl-md-2 pr-xl-2">
						<section class="border-w-10 h-100 d-flex flex-column">
							<div class="content-card">
								<div class="content-card-title content-card-title-color">
									<h4>Injetora</h4>
									<ActivityIcon/>
								</div>
								<div class="body-cards">
									Tempo estimado para finalização do processo: {{ tempoEstimadoFinalizacao }}
								</div>
							</div>
							<div
								class="footer-card d-flex align-items-center justify-content-center"
								v-bind:class="classCardsHeader"
								:title="machine.nome"
							>
								<span>
									{{ machine.nome }}
								</span>
							</div>
						</section>
					</b-col>

					<b-col cols="12" md="6" xl="3" class="mb-2 p-0 pl-xl-2 pr-md-2">
						<section class="grid-container">
							<div class="border-w-10 grid-item1">
								<div class="content-card-grid">
									<div class="content-card-title content-card-title-color">
										<h4
											class="title-text-grid"
											title="Quantidade Material Utilizado"
										>
											Quantidade Material Utilizado
										</h4>
									</div>
								</div>
								<div
									class="footer-card footer-card-grid"
									v-bind:class="classCardsHeader"
								>
									<div class="footer-grid-text">
										{{ machine.ordemProducao.quantidadeMaterialUtilizado }}
									</div>
								</div>
							</div>
							<div
								class="border-w-10 grid-item2"
							>
								<div class="content-card-grid">
									<div class="content-card-title content-card-title-color">
										<h4
											class="title-text-grid"
											title="Nº Batida"
										>
											Nº Batida
										</h4>
									</div>
								</div>
								<div
									class="footer-card footer-card-grid"
									v-bind:class="classCardsHeader"
								>
									<div class="footer-grid-text">
										{{ machine.ordemProducao.numeroBatida }}
									</div>
								</div>
							</div>

							<!-- Segunda linha -->
							<div
								class="border-w-10  grid-item3"
							>
								<div class="content-card-grid">
									<div class="content-card-title content-card-title-color">
										<h4
											class="title-text-grid"
											title="Peças Produzidas"
										>
											Peças Prod.
										</h4>
									</div>
								</div>
								<div
									class="footer-card footer-card-grid"
									v-bind:class="classCardsHeader"
								>
									<div class="footer-grid-text">
										{{ machine.ordemProducao.pecasProduzidas }}
									</div>
								</div>
							</div>
							<div
								class="border-w-10 grid-item4"
							>
								<div class="content-card-grid">
									<div class="content-card-title content-card-title-color">
										<h4
											class="title-text-grid"
											title="Peças Restantes"
										>
											Peças Rest.
										</h4>
									</div>
								</div>
								<div
									class="footer-card footer-card-grid"
									v-bind:class="classCardsHeader"
								>
									<div class="footer-grid-text">
										{{ machine.ordemProducao.pecasRestantes }}
									</div>
								</div>
							</div>
							<div
								class="border-w-10 grid-item5"
							>
								<div class="content-card-grid">
									<div class="content-card-title content-card-title-color">
										<h4
											class="title-text-grid"
											title="Peças Totais"
										>
											Peças Totais
										</h4>
									</div>
								</div>
								<div
									class="footer-card footer-card-grid"
									v-bind:class="classCardsHeader"
								>
									<div class="footer-grid-text">
										{{ machine.ordemProducao.pecasTotais }}
									</div>
								</div>
							</div>
						</section>
					</b-col>

					<b-col cols="12" md="6" xl="3" class="mb-2 p-0 pl-md-2">
						<section class="bg-dark h-100 d-flex flex-column">
							<div class="content-card">
								<div class="content-card-title">
									<span style="font-size: 24px;">
										Status
									</span>
									<span style="font-size: 12px; margin-top: 5px;">
										H &nbsp; &nbsp; &nbsp; &nbsp; MIN&nbsp; &nbsp; &nbsp; &nbsp; SEG
									</span>
								</div>
								<div class="content-card-title">
									<span
										v-bind:class="status === 'Offline' ? 'text-status-offline' : ''"
										style="font-size: 12px; margin-top: -.5rem;"
									>
										{{ status }}
									</span>
									<TimeStatus
										class="time-status"
										:colorNumbers="'machine-text-sinoptico'"
										:machine="machine"
										:loading="loading"
										:styles="false"
										:stopStopwatch="connectionError"
									/>
								</div>
							</div>

							<div
								class="footer-card d-flex align-items-center justify-content-center"
								v-bind:class="classCardHeaderStatus"
								:title="machine.status.replace('_', ' ')"
							>
								<span>
									{{ machine.status.replace('_', ' ') }}
								</span>
							</div>
						</section>
					</b-col>
				</b-row>

				<b-row class="my-3">
					<b-overlay
						:show="loading"
						rounded="sm"
						class="dashboard-overlay w-100"
					>
						<section
							class="box-timeseries w-100 h-100"
							v-bind:class="classColorBorderTendencia"
						>
							<h3 class="m-0">Tendência Online</h3>
							<div class="body-chart">
								<ECharts
									autoresize
									v-if="ready"
									:options="options"
									style="width: 100%; height: 100%;"
								/>
							</div>
						</section>
					</b-overlay>
				</b-row>

				<b-row>
					<b-col cols="12" md="8" lg="4" class="mb-3 p-0 pr-md-2">
						<CardBaseS
							:title="machine.ciclo.title"
							:status="machine.status"
							:tempoEsperado="machine.ciclo.tempoEsperado"
							:tempoAtual="machine.ciclo.tempoAtual"
						/>
					</b-col>
					<b-col cols="12" md="4" lg="2" class="mb-3 p-0 pl-md-2 pr-lg-2">
						<CardBaseM
							:title="machine.ciclo.title"
							:status="machine.status"
							:tempoMedio="machine.ciclo.tempoMedio"
							@filter="filterTempoMedioCiclo"
						/>
					</b-col>
					<b-col cols="12" md="8" lg="4" class="mb-3 p-0 pl-lg-2 pr-md-2">
						<CardBaseS
							:title="machine.dosagem.title"
							:status="machine.status"
							:tempoEsperado="machine.dosagem.tempoEsperado"
							:tempoAtual="machine.dosagem.tempoAtual"
						/>
					</b-col>
					<b-col cols="12" md="4" lg="2" class="mb-3 p-0 pl-md-2">
						<CardBaseM
							:title="machine.dosagem.title"
							:status="machine.status"
							:tempoMedio="machine.dosagem.tempoMedio"
							@filter="filterTempoMedioDosagem"
						/>
					</b-col>
				</b-row>
			</div>
		</template>
	</BaseEmpty>
</template>

<script>
	import dayjs from "dayjs";
	import ECharts from "vue-echarts";
	import "echarts/lib/chart/line";
	import "echarts/lib/component/axis";
	import "echarts/lib/component/tooltip";
	import "echarts/lib/component/dataZoom";
	import "echarts/lib/component/legend";

	import BaseEmpty from "@/templates/BaseEmpty";
	import CardBaseS from "./components/CardBaseS";
	import CardBaseM from "./components/CardBaseM";
	import TimeStatus from "@/components/TimeStatus.vue";
	import { formatTime } from "@/helpers/common";
	import { MaquinasService } from "@/services/maquinas";
	import Toast from "@/components/Toast";

	import "./components/styles.css";

	const UPDATE_TIMEOUT = 1 * 1000;

	export default {
		components: {
			BaseEmpty,
			CardBaseS,
			CardBaseM,
			ECharts,
			TimeStatus,
			Toast
		},

		props: {
			id: {
				type: Number,
				default: () => localStorage.getItem("sinopticoUltimaInjetora") || 0,
				required: false
			}
		},

		data () {
			return {
				ready: true,
				timeOffline: "-",
				maquina: [],
				maquinas: [],
				maquinasService: new MaquinasService(),
				updateInterval: 0,
				loading: false,
				updating: false,
				machine: {
					nome: "",
					status: "",
					inicioStatus: dayjs(),
					ciclo: {
						title: "CICLO",
						tempoEsperado: 0,
						tempoAtual: 0,
						tempoMedio: 0
					},
					dosagem: {
						title: "DOSAGEM",
						tempoEsperado: 0,
						tempoAtual: 0,
						tempoMedio: 0
					},
					ordemProducao: {
						nomeProcesso: "",
						quantidadeMaterialUtilizado: 0,
						numeroBatida: 0,
						pecasProduzidas: 0,
						pecasRestantes: 0,
						pecasTotais: 0
					},
					eventoProducao: {
						minDataInicial: "-"
					},
					medicaoPulso: {}
				},
				classCardsHeader: null,
				classCardHeaderStatus: null,
				classColorBorderTendencia: null,
				status: "",
				tempoEstimadoFinalizacao: "--:--:--",
				semOrdemProducao: false,
				options: {
					dataZoom: [{
						id: "dataZoomX",
						type: "slider",
						xAxisIndex: [0],
						filterMode: "empty",
						top: "0px"
					}],
					height: "50%",
					tooltip: {
						trigger: "item",
						formatter: params => `
							${dayjs(params.value[0]).format("DD/MM/YYYY HH:mm:ss.SSS")}<br>
							${params.marker}
							${params.seriesName}: ${params.value[1]} ${params.value[1] % 2 === 0 ? "(Não dosando)" : "(Dosando)"}
						`
					},
					legend: {
						show: true,
						data: ["Pulso de dosagem"],
						bottom: "0px",
						textStyle: {
							color: "black"
						}
					},
					grid: {
						left: "3%",
						right: "4%",
						bottom: "30%",
						top: "40px",
						containLabel: false
					},
					xAxis: {
						type: "time",
						axisLabel: {
							show: false
						}
					},
					yAxis: {
						show: true,
						type: "category",
						data: []
					},
					series: [{
						name: "Pulso de dosagem",
						type: "line",
						step: "end",
						symbol: "roundRect",
						symbolSize: 4,
						connectNulls: false,
						data: [],
						color: []
					}]
				},
				showFiltersForm: false,
				maquinasOptions: [{ value: -1, text: "Selecione uma injetora", notEnable: true }],
				selectedMachine: this.id || localStorage.getItem("sinopticoUltimaInjetora") || null,
				intervalosOptions: [
					{ value: null, text: "Selecione o intervalo", notEnable: true },
					{ value: 0, text: "Mínimo" },
					{ value: 1, text: "1 segundo" },
					{ value: 5, text: "5 segundos" },
					{ value: 10, text: "10 segundos" },
					{ value: 15, text: "15 segundos" },
					{ value: 30, text: "30 segundos" },
					{ value: 60, text: "1 minuto" },
					{ value: 180, text: "3 minutos" },
					{ value: 300, text: "5 minutos" },
					{ value: 600, text: "10 minutos" },
					{ value: 900, text: "15 minutos" },
					{ value: 1800, text: "30 minutos" },
					{ value: 2700, text: "45 minutos" },
					{ value: 3600, text: "1 hora" }
				],
				intervaloSelected: 0,
				intervaloEmMinutos: 1,
				intervaloEmMinutosFiltro: 1,
				estadoFiltrosRapidos: {
					um: true,
					cinco: false,
					dez: false,
					trinta: false
				},
				inicioTempoMedioCiclo: dayjs().subtract(1, "days"),
				inicioTempoMedioDosagem: dayjs().subtract(1, "days"),
				filters: {},
				connectionError: true
			};
		},

		watch: {
			"machine.status": "getClassCardsHeader"
		},

		async mounted () {
			this.getClassCardsHeader();
			this.maquinas = await this.maquinasService.getAllMachinesIncludeOrdemProducao();
			this.mountMaquinasOptions();

			// Monta filtros iniciais padrões
			this.filters = {
				idMaquina: this.selectedMachine,
				startTime: dayjs().subtract(this.intervaloEmMinutosFiltro, "minutes"),
				interval: this.intervaloSelected,
				tempoMedioCiclo: "hora",
				tempoMedioDosagem: "hora"
			};

			this.update();
			this.updateInterval = setInterval(() => { this.update(); }, UPDATE_TIMEOUT);
		},

		methods: {
			getClassCardsHeader () {
				this.status = !this.machine.status || this.machine.status === "OFFLINE" ? "Offline" : "Online";

				switch (this.machine.status) {
					case "PARADA":
						this.classCardsHeader = "bg-color-parada";
						this.classCardHeaderStatus = "bg-color-parada";
						this.classColorBorderTendencia = "border-parada";
						break;
					case "DOSANDO": case "COMPLETANDO":
						this.classCardsHeader = "bg-color-working";
						this.classCardHeaderStatus = "bg-color-online";
						this.classColorBorderTendencia = "border-online";
						break;
					case "CICLO_EXCEDIDO":
						this.classCardsHeader = "bg-color-ciclo-excedido";
						this.classCardHeaderStatus = "bg-color-ciclo-excedido";
						this.classColorBorderTendencia = "border-ciclo-excedido";
						break;
					default:
						this.classCardsHeader = "footer-status-offline";
						this.classCardHeaderStatus = "footer-state-offline";
						this.classColorBorderTendencia = "border-offline";
				}
			},

			getTempoEstimadoFinalizacao () {
				if (!this.machine.ordemProducao || !this.machine.ordemProducao.batidas)
					return "--:--:--";

				const tempo = Math.max(0, ((this.machine.ordemProducao.batidas - this.maquina.eventoProducao.countEventosProducao) * this.machine.ordemProducao.tempoCicloTeorico) / 1000);
				this.tempoEstimadoFinalizacao = formatTime(tempo);
			},

			async update () {
				if (this.updating || this.filters.idMaquina <= 0) return;
				this.updating = true;

				try {
					this.filters.startTime = dayjs().subtract(this.intervaloEmMinutosFiltro, "minutes");

					this.maquina = await this.maquinasService.findOneWithDataProduction(this.filters);
					if (!this.maquina.nome) {
						// Nenhum dado retornado
						this.loading = false;
						this.updating = false;
						return;
					}

					this.connectionError = false;
					localStorage.setItem("sinopticoUltimaInjetora", this.filters.idMaquina);
					this.semOrdemProducao = this.maquina.ordemProducao.nomeProcesso && this.maquina.ordemProducao.nomeProcesso === "-";

					let minDataInicial = "Nenhum processo inicializado";
					if (this.maquina.ordemProducao.nomeProcesso !== "-" && this.maquina.eventoProducao.minDataInicial)
						minDataInicial = dayjs(this.maquina.eventoProducao.minDataInicial).format("DD/MM/YYYY HH:mm:ss");

					const batidas = this.maquina.ordemProducao.batidas;
					const numeroBatida = this.maquina.eventoProducao.countEventosProducao;
					const pecasProduzidas = numeroBatida * this.maquina.ordemProducao.pecasPorBatida;
					const pecasTotais = batidas * this.maquina.ordemProducao.pecasPorBatida;
					const pecasRestantes = pecasTotais - pecasProduzidas;

					let quantidadeMaterialUtilizado = this.maquina.ordemProducao.gramasPorBatida * numeroBatida;
					if (quantidadeMaterialUtilizado >= 1000)
						quantidadeMaterialUtilizado = `${parseFloat(quantidadeMaterialUtilizado / 1000).toFixed(3).replace(".", ",")} kg`;
					else
						quantidadeMaterialUtilizado = `${quantidadeMaterialUtilizado} g`;

					this.machine = {
						nome: this.maquina.nome,
						status: this.maquina.status.substring(0, 6) === "PARADA" ? "PARADA" : this.maquina.status,
						inicioStatus: this.maquina.inicioStatus,
						ciclo: {
							title: "CICLO",
							tempoEsperado: this.maquina.ordemProducao.tempoCicloTeorico || 0,
							tempoAtual: this.maquina.eventoProducao.tempoCicloAtual || 0,
							tempoMedio: (this.maquina.eventoProducao.tempoMedioCiclo || 0) / 1000
						},
						dosagem: {
							title: "DOSAGEM",
							tempoEsperado: this.maquina.ordemProducao.tempoDosagemTeorico || 0,
							tempoAtual: this.maquina.eventoProducao.tempoDosagemAtual || 0,
							tempoMedio: (this.maquina.eventoProducao.tempoMedioDosagem || 0) / 1000
						},
						ordemProducao: {
							nomeProcesso: this.maquina.ordemProducao.nomeProcesso,
							tempoCicloTeorico: this.maquina.ordemProducao.tempoCicloTeorico,
							batidas,
							quantidadeMaterialUtilizado,
							numeroBatida,
							pecasProduzidas,
							pecasRestantes,
							pecasTotais
						},
						eventoProducao: { minDataInicial },
						medicaoPulso: this.maquina.medicaoPulso
					};

					this.getTempoEstimadoFinalizacao();
					this.updateTendenciaOnline();
				} catch (err) {
					this.connectionError = true;
					console.error(err);
				}

				this.loading = false;
				this.updating = false;
			},

			async updateTendenciaOnline () {
				try {
					this.options.series[0].data = this.machine.medicaoPulso.reduce((d, v) => {
						if (v.valor !== null) {
							d.push({
								name: v.dataHora,
								value: [v.dataHora, v.valor]
							});
						}

						return d;
					}, []);

					let colorLine = "#6C757D";
					switch (this.machine.status) {
						case "PARADA":
							colorLine = "#C12332";
							break;
						case "DOSANDO":
						case "COMPLETANDO":
							colorLine = "#28A745";
							break;
						case "CICLO_EXCEDIDO":
							colorLine = "#FFAF20";
							break;
					}

					this.options.series[0].color = [colorLine];
					this.ready = true;
				} catch (err) {
					console.log(err);
					this.$snotify.error(
						"Não foi possível atualizar o gráfico online. Tentando novamente...",
						{ timeout: 3000 }
					);
				}
			},

			filterDropDownActive () {
				this.showFiltersForm = !this.showFiltersForm;
			},

			mountMaquinasOptions () {
				this.maquinas.forEach(maquina => {
					this.maquinasOptions.push({
						value: maquina.id,
						text: maquina.nome
					});
				});
				if (!this.selectedMachine) {
					if (!this.maquinasOptions.find(m => m.value === this.selectedMachine)) {
						if (this.maquinasOptions[1])
							this.selectedMachine = this.maquinasOptions[1].value;
						else
							this.selectedMachine = -1;
					}
				}
			},

			filtroRapidoClicked (valor, cardinal) {
				this.intervaloEmMinutos = valor;
				this.intervaloSelected = valor > 5 ? 5 : 0;

				const properties = Object.getOwnPropertyNames(this.estadoFiltrosRapidos);
				properties.forEach(prop => {
					if (prop != cardinal)
						this.estadoFiltrosRapidos[prop] = false;
				});
			},

			filtrosRapidosClear () {
				for (const key in this.estadoFiltrosRapidos) {
					this.estadoFiltrosRapidos[key] = false;
				}
			},

			search () {
				const isFormValid = this.validateMaquina() && this.validateIntervaloPontos() && this.validateIntervalo();
				this.intervaloEmMinutosFiltro = this.intervaloEmMinutos;
				if (isFormValid) {
					this.filters = {
						idMaquina: this.selectedMachine,
						startTime: dayjs().subtract(this.intervaloEmMinutosFiltro, "minutes"),
						interval: this.intervaloSelected,
						tempoMedioCiclo: this.filters.tempoMedioCiclo,
						tempoMedioDosagem: this.filters.tempoMedioDosagem
					};

					this.showFiltersForm = false;
					this.loading = true;
					this.update();
				}
			},

			validateMaquina () {
				return this.selectedMachine > 0;
			},

			validateIntervaloPontos () {
				return this.intervaloSelected > 0 || this.intervaloSelected === 0;
			},

			validateIntervalo () {
				return this.intervaloEmMinutos > 0;
			},

			filterTempoMedioCiclo (periodo) {
				this.filters.tempoMedioCiclo = periodo;
				this.loading = true;
				this.update();
			},

			filterTempoMedioDosagem (periodo) {
				this.filters.tempoMedioDosagem = periodo;
				this.loading = true;
				this.update();
			}
		},

		beforeDestroy () {
			clearInterval(this.updateInterval);
		}
	};
</script>

<style scoped>
	.content-card-title {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}

	.body-cards {
		display: block;
		color: #AEAEAE;
		margin-top: -12px;
		font-size: 12px;
		min-height: 2.25rem;
	}

	.title-chart-line-2 {
		color: #AEAEAE;
		position: absolute;
		margin-top: -8px
	}

	.body-chart {
		width: 100%;
		height: 80%;
		min-height: 150px;
		display: flex;
		justify-content: center;
	}

	.content-card-title-color {
		color: #20B2AA;
	}

	.content-card {
		margin: 0.3rem 1rem 0.3rem 1rem;
		font-size: 1rem;
		flex-grow: 1;
	}

	.time-status {
		font-size: 35px;
		margin-top: -25px;
		display: flex;
		justify-content: right;
		font-weight: 500;
		color: #FFFFFF;
		font-variant-numeric: tabular-nums;
	}

	.border-w-10 {
		border-top: 10px solid #727272 !important;
		border-radius: 10px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	}

	.bg-dark {
		background-color: #6C757D !important;
		border-radius: 10px;
		color: #FFFFFF;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	}

	.box-timeseries {
		border-width: 1px;
		border-radius: 5px;
		padding: 16px;
	}
	.border-offline {
		border-style: solid;
		border-color: #B2B2B2 ;
	}
	.border-parada {
		border-style: solid;
		border-color: #C12332;
	}
	.border-online {
		border-style: solid;
		border-color: #28A745;
	}
	.border-ciclo-excedido {
		border-style: solid;
		border-color: #FFAF20;
	}

	.footer-card {
		text-align: center;
		color: #FFFFFF;
		border-radius: 0 0 10px 10px;
		font-size: 24px;
		font-weight: 700;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 10px;
		flex-grow: 1;
	}

	.footer-card span {
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.text-status-offline {
		color: #AEAEAE;
	}

	.footer-status-offline {
		background: #AEAEAE;
	}

	.footer-state-offline {
		background: #D9D9D9;
	}

	.grid-box {
		display: flexbox;
		grid-template-columns: 1fr, 2fr;
	}

	.filter {
		color: #6DCFF6;
		background: #FFFFFF;
		max-width: 7rem;
		display: flex;
		padding: 5px 10px;
		line-height: 1.25rem;
		border-radius: 10px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
	}

	.filter-active {
		color: #FFFFFF;
		background-color: #6DCFF6;
	}

	.filter:hover {
		cursor: pointer;
		color: #FFFFFF;
		background: #6DCFF6;
	}

	.filter-wrap {
		display: flex;
		justify-content: center;
	}

	.filters-form {
		border-radius: 10px;
		box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		background-color: white;
		font-size: 1.15rem;
	}

	.filter-button {
		margin-right: 15px;
		border-radius: 10px;
		color: #696969;
		background-color: white;
	}

	.filter-button:hover {
		color: white;
		background-color: #20B2AA;
	}

	.filter-button-pressed {
		color: white;
		background-color: #20B2AA;
	}

	.btn.active {
		color: white!important;
		background-color: #20B2AA!important;
	}

	.search-button {
		background-color: #60d0ff;
		color: #FFFFFF;
		font-size: 2rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 10px 0 10px 0;
		margin-left: 85%;
		height: 3rem;
	}

	.search-button:hover {
		cursor: pointer;
		background-color: #58bce7;
	}

	@keyframes vertical-slide {
		from {
			overflow-y: clip;
			height: 0%;
		}

		to {
			overflow-y: none;
		}
	}

	.grid-container {
		display: grid;
		grid-template-rows: auto auto;
		grid-template-columns: repeat(3, 33.33%);
		grid-template-areas: "a a b" "c d e";
	}

	.grid-item1 {
		grid-area: a;
		margin-bottom: 5px;
	}

	.grid-item2 {
		grid-area: b;
		margin-left: 5PX;
		margin-bottom: 5px;
	}

	.grid-item3 {
		grid-area: c;
	}

	.grid-item4 {
		grid-area: d;
		margin-left: 5PX;
	}

	.grid-item5 {
		grid-area: e;
		margin-left: 5PX;
	}

	.content-card-grid {
		display: block !important;
		margin: 0 5px;
		font-weight: 400;
	}

	.footer-card-grid {
		height: 28px;
		margin-top: -10px;
	}

	.footer-grid-text {
		margin-top: -3px
	}

	.title-text-grid {
		display: block;
		margin-left: 5px;
		margin-right: 5px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		font-size: 20px;
	}

	.footer-card.ordem-producao {
		height: 36px;
	}

	.slide-fade-enter-active, .slide-fade-leave-active {
		transition: all .5s ease;
	}

	.slide-fade-enter, .slide-fade-leave-to {
		max-height: 0;
		opacity: 0;
		overflow: hidden;
	}
	.slide-fade-enter-to, .slide-fade-leave {
		max-height: 300px; /* Baseado na altura da div que contém os filtros */
		opacity: 1;
	}

	.content-offset {
		margin-top: .7rem;
		transition: all 0.6s;
	}

	.content-no-offset {
		transition: all 2.6s;
	}

</style>
