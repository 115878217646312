<template>
	<b-modal ref="modal-media" :title="title" hide-footer centered style="border-radius: 10px" @hidden="$emit('modal-hidden', mediaFilters)">
		<b-row>
			<b-col cols="6">
				<b-button block :pressed.sync="mediaFilters.hora" class="modal-filter-button" @click="buttonClicked('hora')">Última Hora</b-button>
			</b-col>
			<b-col cols="6">
				<b-button block :pressed.sync="mediaFilters.semana" class="modal-filter-button" @click="buttonClicked('semana')">Última Semana</b-button>
			</b-col>
		</b-row>
		<b-row>
			<b-col cols="6">
				<b-button block :pressed.sync="mediaFilters.dia" class="modal-filter-button" @click="buttonClicked('dia')">Último Dia</b-button>
			</b-col>
			<b-col cols="6">
				<b-button block :pressed.sync="mediaFilters.mes" class="modal-filter-button" @click="buttonClicked('mes')">Último Mês</b-button>
			</b-col>
		</b-row>
	</b-modal>
</template>

<script>
	export default {
		name: "MediaModal",

		props: {
			modalTitle: {
				type: String,
				default: ""
			}
		},

		data () {
			return {
				title: this.modalTitle,
				mediaFilters: {
					hora: true,
					semana: false,
					dia: false,
					mes: false
				}
			};
		},

		methods: {
			showModal (modalTitle) {
				this.title = modalTitle;
				this.$refs["modal-media"].show();
			},

			buttonClicked (periodo) {
				const properties = Object.getOwnPropertyNames(this.mediaFilters);
				properties.forEach(prop => {
					if (prop != periodo)
						this.mediaFilters[prop] = false;
				});

				this.$refs["modal-media"].hide();
			}
		}
	};
</script>

<style scoped>
	.modal-filter-button {
		border-radius: 10px;
		color: #696969;
		background-color: white;
	}

	.modal-filter-button:hover {
		color: white;
		background-color: #20B2AA;
	}

	.modal-filter-button-pressed {
		color: white;
		background-color: #20B2AA;
	}

	.btn.active {
		color: white!important;
		background-color: #20B2AA!important;
	}
</style>
