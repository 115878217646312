<template>
	<div :class="colorNumbers">
		<span :class="classObject">
			{{ getTempoStatus(machine.inicioStatus) }}
		</span>
	</div>
</template>

<script>
	import { formatTime } from "../helpers/common";
	import dayjs from "dayjs";

	export default {
		name: "TimeStatus",

		props: {
			machine: {
				type: Object,
				required: true
			},
			loading: {
				type: Boolean,
				default: true
			},
			styles: {
				type: Boolean,
				default: true
			},
			stopStopwatch: {
				type: Boolean,
				default: false,
				required: false
			},
			colorNumbers: {
				type: String,
				default: "machine-text-dash",
				required: false
			}
		},

		data () {
			return {
				nowTime: new Date(),
				updateInterval: null
			};
		},

		watch: {
			stopStopwatch: "getIntervalStopwatch"
		},

		computed: {
			classObject () {
				if (!this.styles) return;

				return {
					"machine-text-em-ciclo": (this.machine.status === "DOSANDO" || this.machine.status === "COMPLETANDO") && !this.machine.semOrdemProducao,
					"machine-text-parada": this.machine.status.substring(0, 6) === "PARADA" && !this.machine.semOrdemProducao,
					"machine-text-ciclo-excedido": this.machine.status === "CICLO_EXCEDIDO" && !this.machine.semOrdemProducao
				};
			}
		},

		mounted () {
			this.getIntervalStopwatch();
		},

		methods: {
			getTempoStatus (time) {
				return formatTime((this.nowTime - dayjs(time)) / 1000);
			},

			getIntervalStopwatch () {
				if (this.stopStopwatch)
					return clearInterval(this.updateInterval);

				this.updateInterval = setInterval(() => {
					this.nowTime = new Date();
				}, 1000);
			}
		},

		beforeDestroy () {
			clearInterval(this.updateInterval);
		}
	};
</script>

<style scoped>
	.machine-text-em-ciclo {
		color: #28A745;
	}

	.machine-text-dash {
		color: #BABABA;
	}

	.machine-text-sinoptico {
		color: #FFFFFF;
	}

	.machine-text-parada {
		color: #C12332;
	}

	.machine-text-ciclo-excedido {
		color: #FFAF20;
	}
</style>
