<template>
	<div
		v-if="isVisible"
		class="toast-class"
		v-bind:class="`${variant} ${classAnimation}`"
	>
		<InfoIcon/>
		<span>{{ message }}</span>
	</div>
</template>

<script>
	export default {
		name: "Toast",

		data () {
			return {
				classAnimation: null,
				toastVariant: null,
				isVisible: null
			};
		},

		props: {
			message: {
				type: String,
				default: "Erro de conexão com o servidor!"
			},
			visible: {
				type: Boolean,
				required: true
			},
			variant: {
				type: String,
				required: false
			}
		},

		watch: {
			visible: "getAlertStatus"
		},

		methods: {
			getAlertStatus () {
				if (this.visible) {
					this.isVisible = true;
					setTimeout(() => {
						this.classAnimation = "slide-in";
					}, 1000);
				} else {
					this.classAnimation = "slide-out";
					setTimeout(() => {
						this.isVisible = false;
					}, 1000);
				}
			}
		}
	};
</script>

<style scoped>
	.toast-class {
		height: 3rem;
		background-color: #0377a5;
		border-radius: 10px 0 0 10px;
		font-size: 20px;
		font-weight: 700;
		display: flex;
		color: #FFFFFF;
		align-items: center;
		padding: 10px;
		position: fixed;
		right: 0px;
		transition: all 1s ease;
		opacity: 0;
		margin-top: -4px;
	}

	.toast-class.slide-out {
		transform: translateX(100%);
	}

	.toast-class.slide-in {
		transform: translateX(0);
		opacity: 1;
	}

	.error {
		background: #C12332 !important;
	}

	.info {
		background: #6dcff6 !important;
	}

	.success {
		background: #28A745 !important;
	}

	span {
		margin-left: 8px;
	}
</style>
