<template>
	<div class="machine-sinoptico">
		<div class="title-row title-col-dark">
			<span class="title">
				{{ title }}
			</span>
			<InfoIcon/>
		</div>
		<div class="body-row">
			<div class="body-left">
				<div class="body-text">
					<div class="body-text-line-1">TEMPO DE {{ title }}</div>
					<div>ESPERADO</div>
				</div>
				<div
					class="d-flex justify-content-center"
					v-bind:class="classColorText"
				>
					<h4 class="body-time">
						{{ tempoEsperadoFormatted }}
					</h4>
					<div class="body-time-complement">
						<div style="margin-left: 5px">SEG</div>
					</div>
				</div>
			</div>
			<div class="body-right">
				<div class="body-text">
					<div class="body-text-line-1">TEMPO DE {{ title }}</div>
					<div>ATUAL</div>
				</div>
				<div
					v-bind:class="classColorTextTempoAtual"
					class="d-flex justify-content-center"
				>
					<h4 class="body-time">
						{{ tempoAtualFormatted }}
					</h4>
					<div class="body-time-complement">
						<div style="margin-left: 5px">SEG</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import "./styles.css";

	export default {
		name: "CardBaseS",

		props: {
			status: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			tempoEsperado: {
				type: Number,
				required: true
			},
			tempoAtual: {
				type: Number,
				required: true
			},
			loading: {
				type: Boolean,
				default: true
			},
			showBackButton: {
				type: Boolean,
				default: true
			}
		},

		data () {
			return {
				classColorText: null,
				classColorTextTempoAtual: null,
				tempoAtualFormatted: null,
				tempoEsperadoFormatted: null
			};
		},

		watch: {
			status: "getClassColorStatus",
			tempoAtual: "setTempoAtual",
			tempoEsperado: "setTempoEsperado"
		},

		mounted () {
			this.getClassColorStatus();
			this.setTempoEsperado();
			this.setTempoAtual();
		},

		methods: {
			getClassColorStatus () {
				if (this.status === "OFFLINE") {
					this.classColorText = "text-color-grey";
					this.classColorTextTempoAtual = "text-color-offline";
					return;
				}

				this.classColorText = "text-color-black";
				this.classColorTextTempoAtual = this.tempoAtual > this.tempoEsperado ? "text-color-parada" : "text-color-online";
			},

			setTempoAtual () {
				this.tempoAtualFormatted = parseFloat(this.tempoAtual / 1000).toFixed(3).replace(".", ",");
				this.getClassColorStatus();
			},

			setTempoEsperado () {
				this.tempoEsperadoFormatted = parseFloat(this.tempoEsperado / 1000).toFixed(3).replace(".", ",");
				this.getClassColorStatus();
			}
		}
	};
</script>

<style scoped>
	.body-left {
		width: 100%;
		border-right: solid 1px;
		border-color: #AEAEAE;
	}
	.body-right {
		width: 100%;
		padding-left: 5px;
	}

	.body-text {
		padding: 16px 16px 4px 16px;
	}

	.body-time {
		padding-left: 16px;
	}

	.body-text-line-1 {
		overflow: hidden;
		margin-bottom: -6px;
	}

	.body-time-complement {
		display: block;
		height: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: .7rem;
		line-height: 21px;
		text-align: center;
		margin-top: -.5rem;
		padding-bottom: 37px;
	}
</style>
