<template>
	<div class="machine-sinoptico">
		<div
			class="title-row title-col-dark"
			v-bind:class="classColorHead"
		>
			<span class="title">
				MÉDIA
			</span>
			<SettingsIcon class="icons" @click="showMediaModal()"/>
		</div>
		<div class="body-row">
			<div>
				<div class="body-text">
					<div class="body-text-line-1">TEMPO</div>
					<div class="body-text-line-1">MÉDIO DE</div>
					<div class="body-text-line-1">{{ title }}</div>
				</div>
			</div>
			<div>
				<div
					class="body-text"
					v-bind:class="classColorText"
				>
					<div class="body-right">SEG</div>
					<div class="body-right">
						<h4 class="body-time">
							{{ tempoMedioFormatted }}
						</h4>
					</div>
				</div>
			</div>
		</div>
		<div class="footer-row">
			{{ mediaFilter.cardText }}
		</div>
		<MediaModal ref="modal-media" :title="modalTitle" @modal-hidden="modalClosed"></MediaModal>
	</div>
</template>

<script>
	import "./styles.css";
	import MediaModal from "./MediaModal.vue";

	const filtersText = Object.freeze({
		dia: "ÚLTIMO DIA",
		hora: "ÚLTIMA HORA",
		mes: "ÚLTIMO MÊS",
		semana: "ÚLTIMA SEMANA"
	});

	export default {
		name: "CardBaseM",

		components: {
			MediaModal
		},

		props: {
			status: {
				type: String,
				required: true
			},
			title: {
				type: String,
				required: true
			},
			tempoMedio: {
				type: Number,
				required: true
			},
			loading: {
				type: Boolean,
				default: true
			},
			showBackButton: {
				type: Boolean,
				default: true
			}
		},

		data () {
			return {
				classColorHead: null,
				classColorText: null,
				tempoMedioFormatted: null,
				modalTitle: "",
				mediaFilter: {
					activeFilter: "hora",
					cardText: "ÚLTIMA HORA"
				}
			};
		},

		watch: {
			status: "getClassColorStatus",
			tempoMedio: "setTempoMedio"
		},

		mounted () {
			this.getClassColorStatus();
			this.setTempoMedio();
		},

		methods: {
			getClassColorStatus () {
				this.classColorText = "text-color-black";

				switch (this.status) {
					case "PARADA":
						this.classColorHead = "bg-color-parada";
						break;
					case "DOSANDO": case "COMPLETANDO":
						this.classColorHead = "bg-color-working";
						break;
					case "CICLO_EXCEDIDO":
						this.classColorHead = "bg-color-ciclo-excedido";
						break;
					default:
						this.classColorHead = "bg-color-offline";
						this.classColorText = "text-color-grey";
				}
			},

			setTempoMedio () {
				this.tempoMedioFormatted = this.tempoMedio ? parseFloat(this.tempoMedio).toFixed(3).replace(".", ",") : "0,000";
			},

			showMediaModal () {
				this.modalTitle = "Configuração de exibição do tempo médio de " + this.title.toLowerCase();
				this.$refs["modal-media"].showModal(this.modalTitle);
			},

			modalClosed (filters) {
				const properties = Object.getOwnPropertyNames(filters);
				properties.forEach(prop => {
					if (filters[prop] === true) {
						this.mediaFilter.activeFilter = prop;
						this.mediaFilter.cardText = filtersText[prop];
					}
				});

				this.$emit("filter", this.mediaFilter.activeFilter);
			}
		}
	};
</script>

<style scoped>
	.body-right {
		padding: 0 5px;
		display: flex;
		justify-content: right;
	}

	.body-text {
		padding: 16px 4px 4px 4px;
	}

	.body-time {
		margin-top: -.5rem;
	}

	.body-text-line-1 {
		overflow: hidden;
		margin-bottom: -6px;
		text-align: center;
	}

	.footer-row {
		display: block;
		height: 100%;
		font-style: normal;
		font-weight: 400;
		font-size: 1.1rem;
		line-height: 21px;
		text-align: center;
		padding: 5px;
		color: #20B2AA;
		margin-top: .25rem;
	}

	.icons {
		cursor: pointer;
	}
</style>
